<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="500px" persistent>
      <v-card >
        <v-toolbar color="#00439B" elevation="0">
        <v-toolbar-title class="white--text">Update Project</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="update" lazy-validation>
            <v-card-text>
               <v-autocomplete :loading="loading.projects" :disabled="overlay" label="Main Project" v-model="form.parent_id" :items="arrays.projects" item-text="name" item-value="id" :search-input.sync="project_search"> </v-autocomplete>
              <v-text-field :disabled="overlay" label="Project Name" v-model="form.name" :rules="rules.name"> </v-text-field>
              <v-text-field :disabled="overlay" label="Company Name" readonly v-model="company_name"> </v-text-field>
              <v-select :disabled="overlay" label="Type" :items="type" v-model="form.type" :rules="rules.type" @change="listen"> </v-select>
              <div v-if="form.type == 'private'"  > 
                 <v-autocomplete
                  v-model="form.users"
                  :items="users"
                  item-text="name"
                  chips
                  label="Select Users"
                  item-value="user_company_id"
                  multiple
                  :search-input.sync="search"
                  :loading="loadings.search"
                >
                </v-autocomplete>
              </div>
            </v-card-text>
          <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn type="submit" text color="#0A009B" :loading="overlay"> Update </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>

</template>
<script type="text/javascript">
  export default {
    props : {
      dialog: Boolean,
      recievedData : Object,
      usersList: Array,
      admin : Object
    },
    data()
    {
      return {
        company_name: null,
        me : null,
        form : {
          id: null,
          users : null,
          removetag : null,
          parent_id : null,
          company_id: null,
          user_company_id : null,
          name: null,
          percentage : 1,
          type: null,
          disturb: false,
        },
        type : ['global', 'private'],
        rules: {
          name : [v => !!v || 'Please provide project name'],
          percentage : [v=> !!v || 'Please provide percentage' ,v => ( !isNaN(v) ) || "Please provide a percentage"],
          users: [],
          type : [v => !!v || 'Please select project type'],
          disturb : [ v => !!v || 'Please provide project disturb' , v=> (!isNaN(v)) || 'Please provide only number']
        },
        overlay: false,
        loading : {
          search : false,
          projects : false,
        },
        search : null,
        project_search : null,
        status : {
          search : false,
          projects : false,
          projects_more : false
        },
        arrays : {
          projects : []
        },
      }
    },
    computed : {
      show:{
        get(){
          if (this.recievedData) {
            this.populate()
          }
          if (this.usersList) {
            this.populateUsers()
          }
          return this.dialog
        },
        set(value)
        {
          this.$emit('input', value)
        }
      },
    },
    watch: {
      search(val)
      {
        if(this.status.search)
        {
           val = val.trim()
          if(val)
          {
            if(!this.loading.search) 
            {
              this.loading.search = true
              setTimeout( () => {
                this.searchUser(val)
              }, 1000)
            }
          }
        }
      },
      project_search(val)
      {
        if(this.status.projects_more)
        {
          if(this.status.project)
          {
            val = val.trim()
            if(val)
            {
              this.loadings.projects = true
              setTimeout( () => {
                this.searchProject(val)
              }, 1000)
            }
          }
        }
      }
    },
    methods: {
      searchUser(name)
      {
        this.axios.get("/userscompanies?company_id=" + this.me.default_user_company.company_id + '&fullname='+name)
        .then(({ data }) => {
          this.loading.search = false;
          this.users = this.arrange(data.users_company)
        }); 
      },
      searchProject(name)
      {
        this.axios.get('/projects?company_id='+this.me.default_user_company.company.id+'&name='+name)
        .then( ({data}) => {
          this.loading.projects = false;
          this.arrays.projects = data.projects
        })
      },
      update()
      {
        this.checkUsers()
        this.form.users = this.arrangeToApi()
        if (this.$refs.form.validate() ) {
          this.overlay = true
          this.axios.put('/projects/' + this.form.id , this.form)
          .then( () => {
            this.overlay = false
            this.$emit('refresh', true)
            this.cancel()
          })
          .catch( (error) => {
            console.log(error.response)
          })
          .finally( () => {
            this.overlay = false
          })
        }
      },
      arrangeToApi()
      {
        let p = []
        for(let i in this.form.users)
        {
          if(!isNaN(i) )
          {
            p.push(this.form.users[i])
          }
        }
        return p
      },
      checkUsers()
      {
        if (this.form.type == 'private' && this.form.users.length <= 0) {
         this.rules.users.push('Please select users for the project')
        }else{
          this.rules.users = []
        }
      },
      getProjects()
      {

        this.axios.get('/projects?company_id='+this.me.default_user_company.company.id)
        .then( ({data}) => {
          if(data.meta.total > 10)
          {
            this.status.projects_more = true
            this.status.projects = true
          }
          this.arrays.projects = data.projects
        })
        
      },
      cancel()
      {
        this.$refs.form.resetValidation()
        this.show = false
      },
      populate()
      {
        this.form.id = this.recievedData.id
        this.company_name = this.recievedData.company.name
        this.form.company_id = this.recievedData.company.id
        this.form.user_company_id = this.recievedData.user_company_id
        this.form.name = this.recievedData.name
        this.form.type = this.recievedData.type
        this.form.percentage = this.recievedData.percentage
        this.form.disturb = this.recievedData.disturb
        this.form.parent_id = this.recievedData.parent_id
        if (this.recievedData.users) {
          this.getUsers(this.recievedData.users)

        }
        if(this.admin)
        {
          this.me = this.admin
        }
        this.getProjects()
      },
      populateUsers()
      {
        if(this.usersList && this.usersList.length > 10)
        {
          this.status.search = true
        }else{
          this.status.search = false
        }
        this.users = this.arrange(this.usersList)
      },
      arrange(list)
      {

        let n = []
        if(list && list.length > 0)
        {
          for(let i in list)
          {
            if(!isNaN(i))
            {
              let d = {
                user_company_id : list[i].user.current_user_company_id,
                name : list[i].user.first_name + ' ' + list[i].user.last_name
              }
              n.push(d)
            }
          }
        }
        return n
      },
      getName(item)
      {
        return `${item.user.first_name} ${item.user.last_name}` 
      },
      increment()
      {
        this.form.percentage += 1
      },
      decrement()
      {
        let v = this.form.percentage -= 1
        if (v < 0){
          this.form.percentage = 0
        }
      },
      listen()
      {
        if (this.form.type ==  'global') {
          this.form.users = []
          this.form.disturb = false
          this.form.percentage = 1
        }
      },
      getUsers(users)
      {
        let values = []
    
        if (users.length > 0) {
          for(let i in users)
          {
            if(!isNaN(i) )
            {
               if (users[i]) {
                let d = {
                  user_company_id : users[i].user_company_id,
                  name : users[i].user_company.user.first_name + ' ' + users[i].user_company.user.last_name
                }
                values.push(d)
              }
            }
           
          }
        }
        this.form.users = values
        this.form.removetag = values
      },

    }
  }
</script>