<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="500px" persistent>
      <v-card >
        <v-toolbar color="#00439B" elevation="0">
        <v-toolbar-title class="white--text">New Project</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="submit" lazy-validation>
            <v-card-text>
              <v-autocomplete :loading="loading.projects" label="Main Project" v-model="form.parent_id" :items="arrays.projects" item-text="name" item-value="id" :search-input.sync="project_search"> </v-autocomplete>
              <v-text-field label="Project Name" v-model="form.name" :rules="rules.name" :disabled="overlay"> </v-text-field>
              <v-text-field label="Company Name" readonly v-model="company_name" :disabled="overlay"> </v-text-field>
              <v-select label="Type" :items="type" v-model="form.type" :rules="rules.type" @change="listen" :disabled="overlay"> </v-select>
              <div v-if="form.type == 'private'"  >
                <v-autocomplete
                  chips
                  label="Users"
                  item-value="user_company_id"
                  multiple
                  :disabled="overlay"
                  v-model="form.users"
                  :items="users"
                  item-text="name"
                  :rules="rules.users"
                  :search-input.sync="search"
                  
                >
                </v-autocomplete>
              </div>
            </v-card-text>
          <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn type="submit" text color="#0A009B" :loading="overlay"> submit </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>

    </v-dialog>
  </div>

</template>
<script type="text/javascript">
  export default {
    props : {
      dialog: Boolean,
      recievedData : Object,
      usersList: Array,
      myInfo : Object
    },
    data()
    {
      return {
        users: [],
        me : null,
        company_name: null,
        headers : [
          {
            text : 'Name',
            value : 'name'
          }
        ],
        arrays : {
          projects : []
        },
        form : {
          users : [],
          company_id: null,
          parent_id : null,
          user_company_id : null,
          name: null,
          percentage : 1,
          type: null,
          disturb: false,
        },
        type : ['global', 'private'],
        rules: {
          users : [ ],
          name : [v => !!v || 'Please provide project name'],
          percentage : [v=> !!v || 'Please provide percentage' ,v => ( !isNaN(v) ) || "Please provide a percentage"],
          type : [v => !!v || 'Please select project type'],
          disturb : [ v => !!v || 'Please provide project disturb' , v=> (!isNaN(v)) || 'Please provide only number']
        },
        overlay: false,
        status : {
          search : false,
          projects : false,
          projects_more : false
        },
        timer: {
          search : 0,
        },
        loading : {
          search : false,
          projects : false,
        },
        search : null,
        project_search : null
        
      }
    },
    computed : {
      show:{
        get(){
          if (this.recievedData) {
            this.populate()
          }
          if (this.usersList) {
            this.populateUsers()
          }
          if(this.myInfo)
          {
            this.populateMe()
          }
          return this.dialog
        },
        set(value)
        {
          this.$emit('input', value)
        }
      },
      checkPrivate() {
        if (this.form.type == 'private') {
          return [ v => !!v || 'Please select users for the project']
        }else{
          return []
        }
        
      }
    },
    watch: {
      myInfo: function(val)
      {
        if(val)
        {
          this.me = val
        }
      },
      search(val)
      {
        if(this.status.search)
        {
          val = val.trim()
          if(val)
          {
              if(!this.loadings.search) 
              {
                this.loadings.search = true
                setTimeout( () => {
                  this.searchUser(val)
                }, 1000)
              }
          }
        }
      },
      project_search(val)
      {
        if(this.status.projects_more)
        {
          if(this.status.project)
          {
            val = val.trim()
            if(val)
            {
              this.loadings.projects = true
              setTimeout( () => {
                this.searchProject(val)
              }, 1000)
            }
          }
        }
      }
    },
    methods: {
      searchUser(name)
      {
        this.axios.get("/userscompanies?company_id=" + this.me.default_user_company.company_id + '&fullname='+name)
        .then(({ data }) => {
          this.loadings.search = false;
          this.users = this.arrange(data.users_company)
        });
      },
      searchProject(name)
      {
        this.axios.get('/projects?company_id='+this.recievedData.id+'&name='+name)
        .then( ({data}) => {
          this.loadings.projects = false;
          this.arrays.projects = data.projects
        })
      },
      submit()
      {
        if (this.form.type == 'global') {
          this.form.users = []
        }
        this.checkUsers()

        this.form.user_company_id = this.me.default_user_company.id
        if (this.$refs.form.validate() ) {
          this.overlay = true
          this.axios.post('/projects', this.form)
          .then( () => {
            
            this.$emit('refresh', true)
            this.cancel()
          })
          .finally( () => {
            this.overlay = false
          })
        }
      },
      cancel()
      {
        this.$refs.form.resetValidation()
        this.form.name = null
        this.form.type = null
        this.form.users = null
        this.show = false
      },
      checkUsers()
      {
        if (this.form.type == 'private' && this.form.users.length <= 0) {
         this.rules.users.push('Please select users for the project')
        }else{
          this.rules.users = []
        }
      },
      populate()
      {
        this.company_name = this.recievedData.name
        this.form.company_id = this.recievedData.id
        this.getProjects()
      },
      getProjects()
      {
        
        this.axios.get('/projects?company_id='+this.recievedData.id)
        .then( ({data}) => {
          if(data.meta.total > 10)
          {
            this.status.projects_more = true
            this.status.projects = true
          }
          this.arrays.projects = data.projects
        })
        
      },
      populateUsers()
      {
        if(this.usersList && this.usersList.length > 10)
        {
          this.status.search = true
        }else{
          this.status.search = false
        }
        this.users = this.arrange(this.usersList)
        
      },
      arrange(list)
      {
        let n = []
        if(list && list.length > 0)
        {
          if(list.length > 10)
          {
            this.status.search = true
          }
          for(let i in list)
          {
            if(!isNaN(i))
            {
              let d = {
                user_company_id : list[i].user.current_user_company_id,
                name : list[i].user.first_name + ' ' + list[i].user.last_name
              }
              n.push(d)
            }
          }
        }
        return n
      },
      populateMe()
      {
        this.me = this.myInfo
      },
      increment()
      {
        this.form.percentage += 1
      },
      decrement()
      {
        let v = this.form.percentage -= 1
        if (v < 0){
          this.form.percentage = 0
        }
      },
      listen()
      {
        if (this.form.type ==  'global') {
          this.form.users = null
          this.form.disturb = false
          this.form.percentage = 1
        }
      },
      getName(item)
      {
        return `${item.user.first_name} ${item.user.last_name}` 
      },
    }
  }
</script>