<template>
    <v-container>
      <h3>Company Management</h3>
      <p class="text-dark">Manage your business and your employees using Etousoft. Get the most out of the best management tools to help your business stay on the right track.</p>
      <v-row align="center" dense>
        <v-col cols="12" lg="2" md="2">
        <div class="d-flex align-center justify-content-center border rounded  h-100">
            <v-img @error="checkLoader" v-if="arrays.me && arrays.me.default_user_company ? arrays.me.default_user_company.company.logo : '' " height="100" :src="arrays.me.default_user_company.company.logo | getBaseURL" > </v-img>
            <v-img v-else src="@/assets/default.svg" height="75" > </v-img>
          </div>
        </v-col>
        <v-col cols="12" lg="10" md="10">
          <small class="text-caption text-secondary mb-0">Current Company</small>
          <h3> {{ arrays.me && arrays.me.default_user_company ? arrays.me.default_user_company.company.name : 'Loading..' }} </h3>
        </v-col>
      </v-row>
      <v-tabs grow color="#0A009B" center-active show-arrows class="border rounded mt-4" v-model="tab" >
        <v-tab  :dislabed="tabs">
          Company Information
        </v-tab>
        <v-tab  :disabled="tabs">
          Users
        </v-tab>
        <v-tab  :disabled="tabs">
          Users in Department
        </v-tab >
        <v-tab :disabled="tabs">
          Departments
        </v-tab >
        <v-tab :disabled="tabs">
          Projects
        </v-tab>
         <v-tab-item   v-if="arrays.company" >
          <Company
            v-bind:company="arrays.company"
            v-bind:myInfo="arrays.me"
            @refresh="refresh"
          ></Company>
        </v-tab-item>
        <v-tab-item   v-if="arrays.company">
          <UserCompany v-bind:query="query" v-bind:myInfo="arrays.me" v-bind:company="arrays.company" :key="key.users" @upateKey="keyPlus">
          </UserCompany>
        </v-tab-item>
        <v-tab-item   v-if="arrays.company">
          <UserDepartment v-bind:query="query" v-bind:myInfo="arrays.me" v-bind:company="arrays.company" :key="key.users_department" @upateKey="keyPlus">
            </UserDepartment>
        </v-tab-item>
        <v-tab-item  v-if="arrays.company">
          <Department v-bind:query="query" v-bind:company="arrays.company" :key="key.departments" @upateKey="keyPlus">
            </Department>
        </v-tab-item>
        <v-tab-item  v-if="arrays.company">
           <Projects v-bind:query="query" v-bind:company="arrays.company" v-bind:myInfo="arrays.me" :key="key.projects" @upateKey="keyPlus"> </Projects>
        </v-tab-item>
      </v-tabs>
    </v-container>
</template>
<script type="text/javascript">
import Company from "@/components/Manage/CompanyDetails/index";
import Department from "@/components/Manage/Department/index";
import UserCompany from "@/components/Manage/UserCompany/index";
import Projects from "@/components/Manage/Projects/index";
import UserDepartment from "@/components/Manage/UserCompany/Department/index";
export default {
  components: {

    Company,
    Department,
    UserCompany,
    Projects,
    UserDepartment,
  },
  props: {
    myInfo: Object,
  },
  created() {
    if(this.$route.query)
    {
      if(this.$route.query.tab)
      {
     
        this.query.tab = this.$route.query.tab
        if(this.query.tab == 'users')
        {
          this.tab = 1
        }
        if(this.query.tab == 'usersindepartment')
        {
          this.tab = 2
        }
        if(this.query.tab == 'departments')
        {
          this.tab = 3
        }
        if(this.query.tab == 'projects')
        {
          this.tab = 4
        }

      }

      if(this.$route.query.search)
      {
        this.query.search = this.$route.query.search
        

      }
    }
    if(this.myInfo)
    {
     
      this.arrays.me = this.myInfo
      this.getCompany(this.arrays.me.default_user_company.company)
      
    }
    

    
  },
  watch: {
    myInfo: function(val){
      if(val)
      {
        this.arrays.me = val
        if(this.arrays.me.default_user_company.role != 'SA')
        {
          this.$router.push({path : '/account/mycompanies'})
        }else{
          this.getCompany(this.arrays.me.default_user_company.company)
        }
      }
    }
  },
  data() {
    return {
      arrays : {
        me : [],
        company :null,
      },
      tab : 0,
      tabs : true,
      key: {
        users : 0,
        users_department: 0,
        departments: 0,
        projects : 0
      },
      query : {
        tab : null,
        search : null
      }
    };
  },
 
  methods: {
    checkLoader()
    {
      this.arrays.me.default_user_company.company.logo = null
    },
    getCompany(company) {
      if(company)
      {
        this.arrays.company = company
        this.tabs = false
      }
    },
    refresh() {
      if(this.arrays.company.id)
      {
        this.$emit('refresh', true)
      }
      
    },
    keyPlus(except)
    {
      for( let i in this.key)
      {
        if(!i != except)
        this.key[i] += 1
      }
    }
  },
};
</script>