<template>
 <div>
  <v-snackbar v-model="snackbar" top right timeout="2000" color="success" >
     <v-icon class="mr-2" >mdi-check</v-icon>
     <span> Company Details Updated! </span>
     <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
   </v-snackbar>
  <v-dialog v-model="show" persistent max-width="500">
     <v-card tile>
      <v-overlay :absolute="true" :value="loadings.overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
       <v-toolbar color="#00439B" elevation="0">
            <v-toolbar-title class="white--text">Edit Company Details</v-toolbar-title>
        </v-toolbar>
       <v-form @submit.prevent="submit" ref="form" lazy-validation enctype="multipart/form-data" >
         <v-card-text>
             <v-row dense>
               <v-col cols="12" class=" rounded mb-2 d-relative">
                 <p class="mb-1">Company Logo</p>
                 <div>
                  <v-img v-if="display.image" :src="display.image" contain height="150" class="border rounded"> </v-img>
                  <v-img v-else src='@/assets/default.svg' height="150" class="border rounded"> </v-img>
                  <v-btn icon absolute bottom right   color="#0A009B" class="bg-white mb-2" elevation="4" @click="uploadLogo"> 
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                 </div>
               </v-col>
               <v-col cols="12" >
                 <v-text-field
                   label="Name"
                   :rules="rules.name"
                   
                   required
                   v-model="form.name"
                   :disabled="loadings.submit"
                 ></v-text-field>
               </v-col>
               <v-col cols="12">
                 <v-text-field
                   label="Email"
                   required
                    
                   :rules="rules.email"
                   v-model="form.company_email"
                   :disabled="loadings.submit"
                 ></v-text-field>
               </v-col>
               <v-col cols="12" lg="4" md="4">
                 <Countries  v-bind:disabled="loadings.submit" v-model="form.country" v-bind:country="form.country"> </Countries>
               </v-col>
               <v-col cols="12" lg="8" md="8">
                 <v-text-field
                   label="Address"
                   required
                   :rules="rules.address"
                   v-model="form.address"
                   :disabled="loadings.submit"
                  hide-details=""
                 ></v-text-field>
               </v-col>
               <v-col cols="12">
                 <v-text-field
                   label="Contact number (optional)"
                   v-model="form.contact_number"
                   :disabled="loadings.submit"
                 
                   @keyup="accept"
                 >
                 </v-text-field>
               </v-col>
               <v-col cols="12">
                 <timezone @select="select" v-bind:id="form.timezone_id">
                 </timezone>
               </v-col>
                <input ref="uploader" class="d-none" type="file" accept="image/png, image/jpeg, image/bmp" @change="imageUpload" />
   
             </v-row>
         </v-card-text>
         <v-card-actions class="border-top">
           <v-spacer></v-spacer>
           <v-btn  text class="text-secondary" @click="close" :disabled="loadings.submit">
             Close
           </v-btn>
           <v-btn
             color="#0A009B"
             text
             type="submit"
             :loading="loadings.submit"
           >
             update
           </v-btn>
         </v-card-actions>
       </v-form>
     </v-card>
   </v-dialog>
 </div>
</template>
<script>
import timezone from '@/components/Timezone'
import Countries from '@/components/Countries'
export default {
 components: {
  timezone,
  Countries
 },
 props: ['company', 'dialog', 'myInfo'],
 data() {
  return {
   loadings: {
    overlay : false,
    submit : true
   },
   arrays :{
    company : null,
    me : null,
    countries : []
   },
   form : {
    name: null,
    logo: null,
    new_logo : null,
    country : null,
    address: null,
    contact_number: null,
    company_email: null,
    timezone_id: null,
    status : null,
    user_id : null
   },
   display: {
     image : null,
     base : null
   },
   snackbar: false,
    rules : {
     name: [
      (value) => !!value || "Name is required",
      (value) =>
         (value && value.length <= 50) || "Name must be less than 50 characters",
     ],
     email: [
      (value) => !!value || "Email is required",
      (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
     ],
     address: [(value) => !!value || "Address is required"],
     image: [
        (value) =>
          !value || value.size < 2000000 || "Logo size should be less than 2 MB!",
     ],
     country : [
       (value) => !!value || 'Please select a country'
     ]
    },
  }
 },
 computed: {
  show : {
   get() {
    if(this.company)
    {
     this.populate()
    }
    return this.dialog
   },
   set(val)
   {
    this.$emit('input', val)
   }
  }
 },
 watch: {
   company(val)
   {

     if(val)
     {
        this.populate()
        this.loadings.submit = false
     }
   },
   myInfo(val)
   {
     if(val)
     {
       this.populate()
     }
   }
 },
 methods: {
   uploadLogo()
   {
     this.$refs.uploader.click()
   },
  accept()
  {
    if(this.form.contact_number && this.form.contact_number.length > 0)
    {
      let x = this.form.contact_number.replace(/\D/g , '').match(/(\d{0,4})(\d{0,3})(\d{0,4})/)
      this.form.contact_number = !x[2] ? x[1] : x[1] + ' ' + x[2] + ( x[3] ? ' ' +  x[3] : '');
    }
    
  },
  populate() {
    
    if(this.myInfo)
    {
     this.arrays.me = this.myInfo
     let c = this.myInfo.default_user_company.company
     this.form.id = c.id
     this.form.name = c.name
     this.form.company_email = c.company_email
     this.form.country = c.country
     this.form.address = c.address
     this.form.contact_number = c.contact_number
     this.form.timezone_id = c.timezone_id
     if(c.logo)
     {
       this.display.image = this.$options.filters.getBaseURL(c.logo)
     }else{
       this.display.image = null
     }
   
     this.form.logo = c.logo
     this.form.user_id = c.user_id
     this.form.status = c.status
      this.loadings.submit = false
    }
    if(this.arrays.countries.length <= 0)
    {
      this.arrays.countries = this.$countries.names()
    }
  },
  imageUpload() {
    let file = event.target.files[0];

    if (file.size > 1048770) {
      console.log("File is to Biiig");
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        this.display.image = event.target.result;
        this.form.new_logo = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  submit()
  {
     if(this.$refs.form.validate() )
     {
        this.loadings.submit = true;
        if(this.form.contact_number && this.form.contact_number.length > 0)
        {
          this.form.contact_number = this.form.contact_number.replace(/\s/g, '')
        }
       this.axios
       .put("companies/" + this.form.id, this.form)
       .then(() => {
         this.show = false;
         this.snackbar = true
         this.$emit("refresh", true);
       })
       .catch()
       .finally(() => (this.loadings.submit = false));
     }
     
  },
  editCompany() {
    
   },
   close()
   {
    this.show = false
   },
   select(value) {
     this.arrays.company.timezone_id = value;
   },
 }
}
</script>
<style scoped>
.d-relative{
  position: relative;
}
</style>