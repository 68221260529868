<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="400" persistent>
      <v-card>
         <v-toolbar color="#00439B" elevation="0">
          <v-toolbar-title class="white--text">Edit Department</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="update" lazy-validation>
    
          <v-card-text>
         
                <v-text-field
                  label="Company Name"
                  readonly
                  :rules="rules.company"
                  v-model="form.company"
                  :disabled="overlay"
                >
                </v-text-field>
             
                <v-text-field
                  label="Department"
                  required
                  :rules="rules.department"
                  v-model="form.name"
                  :disabled="overlay"
                >
                </v-text-field>
       
          </v-card-text>
          <v-card-actions class="border-top">
            <div class="text-right w-100">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn type="submit" text color="#0A009B" :loading="overlay"> update </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    recievedData: Object,
    company : Object,
  },
  data() {
    return {
      rules: {
        department: [(value) => !!value || "Please provide a department"],
        company: [(value) => !!value || "Please provide company"],
      },
      form: {
        id: null,
        name: null,
        company_id: null,
        company: null,
      },
      arrays : {
        company : null
      },
      status : {
        message : false,
        department : null
      },
      overlay: false,
 
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.$refs.form.resetValidation();
      this.form.company = null;
    },
    update() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        this.axios
          .put("/companydepartments/" + this.form.id, this.form)
          .then(() => {
            this.overlay = false;
            this.cancel();
            this.$emit("refresh", true);
          });
      }
    },
    populate() {
      this.form.id = this.recievedData.id;
      this.form.company_id = this.recievedData.company.id;
      this.form.name = this.recievedData.name;
      this.form.company = this.recievedData.company.name;

    },
  },
};
</script>