<template>
  <div class="text-center">
    <v-dialog v-model="show" width="450" persistent>
      <v-card>
        <v-toolbar color="#9B0A00" elevation="0">
          <v-toolbar-title class="white--text">Delete Project</v-toolbar-title>
        </v-toolbar>
        <div class="p-4">
          <p class="">Are you sure you want to delete ?</p>
          <div >
            <small class="text-caption text-secondary text-uppercase">project</small>
            <p class="mb-0"> {{ form.name }}  </p>
          </div>
          <div >
            <small class="text-caption text-secondary text-uppercase">company</small>
            <p class="mb-0"> {{ form.company_name }}  </p>
          </div>
          <div >
            <small class="text-caption text-secondary text-uppercase">type</small>
            <p class="mb-0"> {{ form.type }}  </p>
          </div>
          <div v-if="form.type == 'private'">
            <div >
              <small class="text-caption text-secondary text-uppercase">percentage</small>
              <p class="mb-0"> {{ form.percentage }}  </p>
            </div>
            <div >
              <small class="text-caption text-secondary text-uppercase">disturb</small>
              <p class="mb-0"> {{ form.disturb ? 'Yes' : 'No' }}  </p>
            </div>
            <div >
              <small class="text-caption text-secondary text-uppercase">users</small>
              <p class="mb-0"> {{ form.users }} </p>
            </div>
          </div>
        </div>
        <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn class="text-secondary" text @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn  text color="#9B0A00" @click="remove" :loading="overlay"> Delete </v-btn>
            </div>
          </v-card-actions>
        
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: Boolean,
    recievedData: Object,
  },
  data() {
    return {
      overlay: false,
      form: {
        id: null,
        company_id: null,
        user_company_id: null,
        name: null,
        percentage: null,
        type: null,
        disturb: null,
        company_name: null,
        users: null,
      },
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
      this.show = false;
    },
    remove() {
      this.overlay = true;
      this.axios.delete("/projects/" + this.form.id).then(() => {
        this.overlay = false;
        this.cancel();
        this.$emit("refresh", true);
      });
    },
    populate() {
      this.form.id = this.recievedData.id;
      this.form.company_name = this.recievedData.company.name;
      this.form.company_id = this.recievedData.company.id;
      this.form.user_company_id = this.recievedData.company.id;
      this.form.name = this.recievedData.name;
      this.form.type = this.recievedData.type;
      this.form.percentage = this.recievedData.percentage;
      this.form.disturb = this.recievedData.disturb;
      this.form.users = this.recievedData.list;
    },
  },
};
</script>