<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="600px" persistent>
      <v-card>
        <v-toolbar color="#00439B" elevation="0">
        <v-toolbar-title class="white--text">Edit User</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="update" lazy-validation>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Company"
                  readonly
                  :rules="rules.company"
                  v-model="form.company"
                  :disabled="overlay"
                  hide-details=""
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  readonly
                  required
                  v-model="form.name"
                  :disabled="overlay"
                  hide-details=""
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="status.form"
                  label="Status"
                  required
                  :rules="rules.status"
                  v-model="form.status"
                  :disabled="overlay"
                  hide-details=""
                ></v-select>
                <!-- <v-text-field
                  label="Status"
                  readonly
                  required
                  :rules="rules.status"
                  v-model="form.status"
                  :disabled="overlay"
                  hide-details=""
                >
                </v-text-field> -->
              </v-col>
              <v-col cols="12" md="6">
                <v-select hide-details="" :disabled="overlay" :readonly="status.admin ? true : false" :items="status.admin ? admin_role : roles" @change="changeRole" item-text="text" label="Role" :rules="rules.department" v-model="new_role" >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  :items="supervisorEntries"
                  :loading="loadings.parent"
                  hide-no-data
                  item-value="id"
                  label="Supervisor"
                  v-model="form.parent"
                  :search-input.sync="search"
                  hint="Start typing to search supervisor"
                  persistent-hint
                  hide-details=""
                  :readonly="status.admin ? true : false"
                  :disabled="overlay "
                  @change="setItem"
                >
                <template v-slot:item="{ item }">{{ item.user.first_name }} {{ item.user.last_name }}</template>
                <template v-slot:selection="{ item }">{{ item.user.first_name }} {{ item.user.last_name }}</template>
                </v-combobox>
              </v-col>
              <v-col cols="12">
                <timezone @select="selectTimezone" v-bind:id="form.timezone_id">
                </timezone>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="border-top">
            <div class="w-100 text-right">
              <v-btn  text class="text-secondary" @click="cancel" :disabled="overlay"> Cancel </v-btn>
              <v-btn type="submit" color="#0A009B" text :loading="overlay"> update </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import timezone from "@/components/Timezone";
export default {
  components: {
    timezone,
  },
  props: {
    dialog: Boolean,
    recievedData: Object,
    company : Object
  },
  data() {
    return {
      rules: {
        parent: [(value) => !!value || "Please provide a parent"],
        company: [(value) => !!value || "Please provide company"],
        role: [(value) => !!value || "Please provide a role"],
      },
      admin_role : [
          {
            text : 'Owner',
            value : 'SA'
          }
        ],
      roles : [
        {
          text : 'Company Admin',
          value : 'CA',
        },
        {
          text : 'Manager',
          value : 'M',
        },
        {
          text : 'Staff',
          value : 'RU',
        },
        

      ],
      new_role : null,
      status: {
        form : ["active", "inactive"],
        all : true,
        selected : false,
        start : true,
        loaded : false,
        populate : false,
        search : false,
        admin : false
      },
      display: {
        total : 0,
      },
      form: {
        id: null,
        role: null,
        name: null,
        status: null,
        status_label: null,
        company_id: null,
        company: null,
        timezone_id: 0,
        parent_id: null,
        parent : null,
        temp_role : null
      },
      parent_data : null,
      overlay: false,
      disabled: {
        parent : true
      },
      u_id : null,
      entries: [],
      loadings: {
        parent : true,
        searching : false
      },
      arrays : {
        backup : null
      },
      search: null,
    };
  },
  computed: {
    show: {
      get() {
        if(this.dialog)
        {
          if(this.recievedData)
          {
            this.populate()
          }
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    supervisorEntries()
    {
      return this.entries.filter( supervisor => {
        return supervisor.id != this.form.id
      })
    }
  },
  watch: {
    search(val)
    {
      if(this.status.search)
      {
        val = val.trim()
        if(val)
        {
          if(this.entries.last_name > 1)
          {
            if(this.loadings.parent) return
            setTimeout( () => {
              this.loadings.parent = true
              this.getCompanies(val)
            }, 200)
          }
        }
      }
      
    }
  },
  methods: {
    changeRole(role)
    {
      this.form.temp_role = role
    },
    selectTimezone(id) {
      this.form.timezone_id = id;
    },
    cancel() {
      this.$refs.form.resetValidation()
      for(let i in this.form)
      {
        this.form[i] = ''
        if(i == 'timezone_id')
        {
          this.form[i] = 0
        }
      }
      this.entries = []
      this.show = false
    },
    update() {
      if (this.$refs.form.validate()) {
        let role = null
 
        if(this.form.temp_role)
        {
          role = this.form.temp_role
        }else{
          role = this.recievedData.role
        }
         if(this.form.parent)
        {
          this.form.parent_id = this.form.parent.id
        }

        let data = {
          id: this.form.id,
          role: role,
          status: this.form.status,
          company_id: this.form.company_id,
          timezone_id: this.form.timezone_id,
          parent_id: this.form.parent_id,
        }
        this.overlay = true;
        this.axios
          .put("/userscompanies/" + data.id, data)
          .then(() => {
            this.overlay = false;
            this.cancel();
            this.$emit("refresh", true);
          });
      }
    },
    populate() {
     
      if(this.recievedData)
      {
        this.new_role = this.recievedData.role
        this.form.role = this.recievedData.role
        if(this.recievedData.role == 'SA')
        {
          this.status.admin = true
        }else{
          this.status.admin = false
        }
        this.form.id = this.recievedData.id
        this.form.company_id = this.recievedData.company_id
        this.form.name =
          this.recievedData.user.first_name +
          " " +
          this.recievedData.user.last_name
      
        this.form.company = this.recievedData.company.name
        this.form.role = this.recievedData.role
        this.form.status = this.recievedData.status
        if(this.recievedData.timezone_id)
        {
          this.form.timezone_id = this.recievedData.timezone_id
        }
        if(this.recievedData.parent_id)
        {
          this.form.parent_id = this.recievedData.parent_id
        }
        if(this.form.role == 'SA')
        {
          this.disabled.parent = false
        }
    
        this.getCompanies()
      }
      
    },
    setItem(val)
    {
      this.form.parent = val
    },
    getCompanies(search)
    {
      let s = ''
      {
        if(search)
        {
          s = search
        }
      }
      this.loadings.parent = true
      let superUrl = "/userscompanies?company_id="+this.recievedData.company_id+"+&roles[0]=SA&roles[1]=M&roles[2]=CA&fullname=" +  s
      this.axios.get(superUrl)
      .then( ({data}) => {
        this.entries = data.users_company
        if(data.users_company.length > 10)
        {
          this.status.search = true
        }else{
          this.status.search = false
        }
           for(let i in data.users_company)
            {
              if(!isNaN(i) )
              {
                // if(data.users_company[i].user_id == this.recievedData.user_id)
                // {
                //   this.$delete(data.users_company, i)
                // }
                if(this.recievedData)
                {
                  if(this.recievedData.parent_id == data.users_company[i].id)
                  {
                    this.form.parent = data.users_company[i]
                  }
                }
              }
            }
        
      })
      .finally( () => {
        this.loadings.parent = false
      
      }) 
    },
  },
};
</script>